$(document).ready( function() {
    $('select').selectpicker();

    $(".img-check").click(function(){
        $(this).toggleClass("check");
        var checkBox = $(this).next(".check-images");
        checkBox.prop("checked", !checkBox.prop("checked"));
        $(".add-color").css("color", "#0c6d93");
    });

    $('input[name=delete_modal],button[name=delete_modal]').on('click', function(e) {
        e.preventDefault();
        var form=$(this).closest('form');
        $('#del-modal').modal({ backdrop: 'static', keyboard: false })
            .on('click', '#del-btn', function(){
                form.submit();
            });
    });
    if($('#myCarousel').length >0) {
        $('.topbarPartner').css('position', 'absolute');
    }

    $('#postal_code').mask('A0A 0A0');

    $("#postal_code").keyup(function () {
        var ucase = $(this).val().toUpperCase();
        $(this).val(ucase);
    });

    $("#homepage_url").change(function() {
        if (this.value.indexOf("http://") !== 0 && this.value.indexOf("https://") !== 0) {
            this.value = "http://" + this.value;
        }
    });

    $('.datepicker').datepicker({ dateformat: 'yyyy-mm-dd' });

    $('#date').mask('0000-00-00');
    $('.datepicker').mask('0000-00-00');

    $('#year_established').mask('0000');

    $('#amount').mask('0ZZZZZZZZZZZZZZZ', {'translation': {Z: { pattern: /[0-9]/, optional: true }}});
});